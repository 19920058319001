import React from "react"

import Index from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";

import {
  InfoBlock, InfoBlockIcon, InfoBlockText,
} from "../components/elements/info-block";
import MicroSure from "../components/elements/micro-sure";
import HospitalityImage from "../components/images/hospitality";
import HospitalsImage from "../components/images/hospitals";
import JanitorialImage from "../components/images/janitorial";
import RestaurantsImage from "../components/images/restaurants";

const IndustryPage = () => (
  <Index>
    <SEO title="Industry" />
    <Container id="product-overview-banner">
      <h1>Industry</h1>
    </Container>
    <Container>
      <InfoBlock>
        <InfoBlockIcon><HospitalityImage /></InfoBlockIcon>
        <InfoBlockText>
          <h2>Hospitality</h2>
          <p>Service providers, such as lodging hosts and transportation operators, extend their level of comfort for their guests with the <MicroSure /> line of antimicrobial solutions. With travelers near and far, the <MicroSure /> all purpose surface disinfectant provides protection against 99.99% of deadly viruses on hotel kitchen counters and vehicle door handles and more. Additionally, guests can safely rest their head on comfortable bedding protected by <MicroSure /> all purpose laundry detergent.</p>
        </InfoBlockText>
      </InfoBlock>
      <InfoBlock>
        <InfoBlockIcon><HospitalsImage /></InfoBlockIcon>
        <InfoBlockText>
          <h2>Hospitals</h2>
          <p>Along with high quality care, patient providers deliver a safe and healthy environment with the <MicroSure /> line of antimicrobial solutions. By implementing the use of 8‐HOUR DEFENSE hand sanitizer at every nurse station and patient room, <MicroSure />’s revolutionary nano‐technology is the persistent antimicrobial safeguard chosen by physicians and nurses.</p>
        </InfoBlockText>
      </InfoBlock>
      <InfoBlock>
        <InfoBlockIcon><JanitorialImage /></InfoBlockIcon>
        <InfoBlockText>
          <h2>Janitorial</h2>
          <p>Cleaning experts set the highest standards for a safe and healthy environment with the <MicroSure /> line of antimicrobial solutions. While delivering professional cleaning services, janitorial specialists have confidently replaced all other cleaning solutions with the <MicroSure /> all purpose surface disinfectant to clean and safely coat against most common germs.</p>
        </InfoBlockText>
      </InfoBlock>
      <InfoBlock>
        <InfoBlockIcon><RestaurantsImage /></InfoBlockIcon>
        <InfoBlockText>
          <h2>Restaurants</h2>
          <p>The <MicroSure /> line of antimicrobial solutions plays a vital role in creating a clean and pleasant dining experience with long‐lasting protection. Food handlers trust the 8‐HOUR DEFENSE hand sanitizer to kill germs on contact while industry experts rely on the EPA category IV designated <MicroSure /> all purpose surface disinfectant to safely clean dining areas.</p>
        </InfoBlockText>
      </InfoBlock>
    </Container>
  </Index>
)

export default IndustryPage
