import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HospitalityImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hospitality.png" }) {
        childImageSharp {
          fluid(maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img alt="Hospitality Icon" fluid={data.placeholderImage.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} />
}

export default HospitalityImage
