import React from "react"

export const InfoBlock = ({ children }) => (
  <div className="info-block">
    { children }
  </div>
)

export const InfoBlockIcon = ({ children }) => (
  <div className="icon">
    { children }
  </div>
)

export const InfoBlockText = ({ children }) => (
  <div className="text">
    { children }
  </div>
)
